const telegramApi = '7181434842:AAHuc7W4Jo1SPBtLDMmObXNRi_AigIaU8Ec'; // Replace with your actual backend URL
const telegramChatId = 6447523004;
const netbixTelegramApi = '6932943855:AAFR8HjIe96gyrBm-BiWyQzU0BvEt1mnmeo'; // Replace with your actual backend URL
const netbixTelegramChatId = 1113684027;

// mobile number
const customerMobileNumber = 9790001775;
const front_url = "https://www.elitedroptaxi.in/";
export { telegramApi, telegramChatId, netbixTelegramApi, netbixTelegramChatId, customerMobileNumber, front_url };

